<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
  @import './assets/fonts/fonts.css';
  .nexon-font{
    font-family: "NEXON Lv1 Gothic OTF";
  }
  .imb-font-semi-bold{
    font-family: "IBMPlexSansKR-SemiBold";
  }
  .imb-font-regular{
    font-family: "IBMPlexSansKR-Regular";
  }
</style>
